import React from "react";

function Contact() {
  return (
    <div className="me">
      <p>Ah you're back, you really want to contact me</p><br />
      <a href="mailto:maxkb02@gmail.com"><li>CONTACT ME!</li></a>

    </div>
  );
}

export default Contact;