import React from "react";

function About() {
  return (
        <div className="about">
          <p>hi, i'm Maxime and i'm french.
I grew up in Normandy where I was born there on 09/06/2002.
My passion has always revolved around computers, social networks, video games, well things where there is an online interaction.
I therefore develop websites or services allowing this connection between the services and the client.</p>
<p>I am therefore constantly improving by learning new technologies.
I work on the front as well as on the back, I am versatile in these areas.
My other big passion is Japan and my dream is to be able to live and work there, so for that I am learning the language on my own.</p>
        </div>
  );
}

export default About;
