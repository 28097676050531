import React from "react";

function NotFound() {
  return (
        <div className="me">
            <h2>LOST ?</h2>
            <p>how did you end up there ?</p>
            
        </div>
  );
}

export default NotFound;
