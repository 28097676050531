import React from "react";
import Header from "../Header";
import Projet from "../Projets";

function home() {
  return (
    <div>
    <Header />
    <Projet />
    </div>
  );
}

export default home;
